.header-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .header-button:hover {
    background-color: #0056b3;
  }
  
  .header-title {
    margin: 0;
  }
  