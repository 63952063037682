.btn-custom {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn-custom:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .btn-custom:active {
    transform: translateY(0);
  }
  
  .btn-icon {
    margin-right: 10px;
    font-size: 20px;
  }
  
  /* Style for the custom dropdown button */
.dropbtn-custom {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .dropbtn-custom:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .dropbtn-custom:active {
    transform: translateY(0);
  }
  
  /* Style for the dropdown icon */
  .dropdown-icon {
    margin-left: 10px;
    font-size: 20px;
  }
  
  /* Style for the dropdown menu */
  .dropdown-menu-custom {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 10px;
    transition: all 0.3s;
  }
  
  /* Style for the dropdown items */
  .dropdown-menu-custom .dropdown-item {
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .dropdown-menu-custom .dropdown-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }
  
  .underline {
    text-decoration: underline;
  }