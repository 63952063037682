.files-dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #f7f7f7;
  cursor: pointer;
}

.dropzone-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.dropzone-content {
  font-size: 16px;
  color: #888;
}

.uploaded-image {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.image-preview {
  max-width: 300px;
  max-height: 300px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.upload-btn {
  font-size: 16px;
}

.delete-btn {
  font-size: 16px;
  background-color: #dc3545;
}

.delete-btn:hover {
  background-color: #c82333;
}

.files-dropzone:hover {
  background-color: #eef7ff;
  border-color: #0056b3;
}
