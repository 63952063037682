.toggle-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .switch {
    position: relative;
    width: 50px;
    height: 25px;
    background: linear-gradient(90deg, #ff416c, #ff4b2b);
    border-radius: 15px;
    transition: background 0.3s;
  }
  
  .switch.active {
    background: linear-gradient(90deg, #00c6ff, #0072ff);
  }
  
  .switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background: white;
    border-radius: 50%;
    transition: all 0.3s;
  }
  
  .switch.active .switch-handle {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .switch-label {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    transition: color 0.3s;
  }
  
  .switch.active + .switch-label {
    color: #0072ff;
  }
  
  .switch.inactive + .switch-label {
    color: #ff4b2b;
  }
  